

.Testimonials {
  padding: 128px 0;
  background: $color-blue-dark;
  position: relative;

  @media (max-width: $size-medium) {
    padding: 80px 0;
  }

  @media (max-width: $size-small) {
    padding: 40px 0;
  }

  &__video {
    margin-top: 64px;
    width: 100%;
    aspect-ratio: 16 / 9;

    @supports not (aspect-ratio: 16 / 9) {
      height: 576px;

      @media (max-width: $size-medium) {
        height: 420px;
      }

      @media (max-width: $size-small) {
        height: 220px;
      }
    }

    @media (max-width: $size-medium) {
      margin-top: 40px;
    }

    @media (max-width: $size-small) {
      margin-top: 24px;
    }
  }
}