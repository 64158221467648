
.Button {
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  height: 44px;
  text-transform: uppercase;
  background: $color-green;
  color: $color-blue-dark;
  border: none;
  border-radius: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.425px;
  padding: 10px 16px;
  cursor: pointer;
  gap: 12px;
  transition: box-shadow ease-in-out 200ms, transform ease-in-out 200ms;
  will-change: box-shadow, transform;

  svg * {
    fill: $color-blue-dark;
  }

  &:hover,
  &:focus {
    transform: scale3d(1.01, 1.01, 1);
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
    outline: none;
  }

  &:active {
    transform: scale3d(0.99, 0.99, 1);
  }

  &--transparent {
    background: none;
    color: $color-green;

    svg * {
      fill: $color-green;
    }

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
}