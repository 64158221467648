
.Hero {
  scroll-snap-align: start;
  height: 80vh;
  position: relative;
  padding: $navigation-height 0 90px;
  text-align: center;
  min-height: 600px;

  @media (max-width: $size-medium) {
    height: auto;
    padding: $navigation-height + 40px 0 64px + 90px;
    min-height: 0;
  }

  @media (max-width: $size-small) {
    padding: $navigation-height + 24px 0 64px + 40px;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &:before {
    background: url("../../assets/image-hero.jpg") no-repeat center;
    background-size: cover;

    @include retina {
      background-image: url("../../assets/image-hero@2x.jpg");
    }
  }

  &:after {
    background: linear-gradient(0deg, rgba(11, 28, 99, 0.56), rgba(11, 28, 99, 0.56));
  }

  &__heading,
  &__lead {
    display: block;
    color: $color-white;
    max-width: 620px;
    margin: 0 auto;
    text-shadow: 0 8px 10px rgba(0, 0, 0, 0.14), 0 3px 14px rgba(0, 0, 0, 0.12), 0 5px 5px rgba(0, 0, 0, 0.2);
  }

  &__heading {
    margin-bottom: 16px;
  }

  &__lead {
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.02px;
    text-transform: uppercase;

    @media (max-width: $size-medium) {
      font-size: 14px;
      line-height: 19px;
    }

    @media (max-width: $size-small) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__button {
    margin-top: 40px;
  }

  .Container {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}