@import url("https://use.typekit.net/brg7ciq.css");

@import "variables";
@import "mixins";
@import "typography";
@import "layout";

@import "components/button";
@import "components/navigation";
@import "components/video";
@import "components/crossDivider";
@import "components/gallery";
@import "components/footer";

@import "sections/hero";
@import "sections/story";
@import "sections/language";
@import "sections/brands";
@import "sections/preview";
@import "sections/testimonials";
@import "sections/faq";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  min-width: 320px;
  background: $color-blue-dark;
}

.Anchor {
  position: absolute;
  top: -64px;
  left: 0;
}