

.Language {
  scroll-snap-align: start;
  padding: 128px 0;
  background: $color-white;
  position: relative;

  @media (max-width: $size-medium) {
    padding: 80px 0;
  }

  @media (max-width: $size-small) {
    padding: 40px 0;
  }

  h2,
  p {
    color: $color-blue-dark;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 96px;
    margin-top: 64px;

    @media (max-width: $size-medium) {
      grid-template-columns: 1fr;
    }

    @media (max-width: $size-small) {
      margin-top: 40px;
    }
  }

  &__item {

    @media (max-width: $size-medium) {
      display: flex;
      justify-content: center;
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media (max-width: $size-small) {
      flex-direction: column;
      align-items: center;
    }

    svg {
      margin-bottom: 16px;

      @media (max-width: $size-medium) {
        margin-right: 40px;
      }

      @media (max-width: $size-small) {
        margin-right: 0;
      }
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.01em;
      max-width: 270px;
      margin: 0 auto;

      @media (max-width: $size-medium) {
        margin: 0;
        text-align: left;
      }

      @media (max-width: $size-small) {
        text-align: center;
      }
    }

    span,
    h3 {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
    }

    small {
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: -0.02px;
      margin-top: 8px;
    }

    span,
    small,
    h3 {
      display: block;
      font-family: $font-primary;
      color: $color-blue-dark;
      text-transform: uppercase;
      text-align: center;

      @media (max-width: $size-medium) {
        text-align: left;
      }

      @media (max-width: $size-small) {
        text-align: center;
      }
    }

    h3 {
      margin-bottom: 8px;
    }
  }
}