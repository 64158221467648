

.FAQ {
  padding: 128px 0;
  background: $color-blue;
  position: relative;

  @media (max-width: $size-medium) {
    padding: 80px 0;
  }

  @media (max-width: $size-small) {
    padding: 40px 0 56px;
  }

  &__list {
    margin-top: 24px;
    text-align: left;
  }

  &__item {
    display: block;
    background: $color-white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.24);
    color: $color-blue-dark;
    margin-top: 16px;

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.075px;
      text-transform: uppercase;
      color: inherit;
      margin: 0;

      @media (max-width: $size-small) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    p {
      text-align: left;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.075px;
      max-width: none;
      margin: 0;

      @media (max-width: $size-small) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    &Title {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 24px 48px 24px 24px;

      @media (max-width: $size-small) {
        padding: 16px 48px 16px 16px;
      }

      svg {
        position: absolute;
        right: 24px;
        top: 24px;
        width: 20px;
        height: 20px;

        @media (max-width: $size-small) {
          right: 16px;
          top: 16px;
        }
      }
    }

    &Content {
      padding: 0 24px 24px;
      animation: collapseContentAnimation ease-in-out 200ms forwards;

      @media (max-width: $size-small) {
        padding: 0 16px 16px;
      }
    }
  }
}

@keyframes collapseContentAnimation {
  0% {
    transform: translateY(-4px);
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}