

.Preview {
  padding: 128px 0;
  background: $color-blue;
  position: relative;

  @media (max-width: $size-medium) {
    padding: 80px 0;
  }

  @media (max-width: $size-small) {
    padding: 40px 0;
  }

  &__gallery {
    margin: 64px 0;

    @media (max-width: $size-medium) {
      margin: 32px 0;
    }

    @media (max-width: $size-small) {
      margin: 24px 0;
    }
  }
}