$font-primary: erbaum, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-default: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

$color-blue-dark: #0B1C63;
$color-blue: #1D46E3;
$color-red: #E30F13;
$color-yellow: #FEC818;
$color-gray: #DDDDDC;
$color-green: #3BFEB8;
$color-white: #FFFFFF;

$color-black-24: rgba(0, 0, 0, .24);
$color-white-24: rgba(255, 255, 255, .24);

$size-medium: 890px;
$size-small: 420px;