
$footer-height: 116px;

.Footer {
  height: $footer-height;
  background: $color-blue-dark;
  position: relative;

  @media (max-width: $size-small) {
    height: auto;
    padding: 56px 0;
  }

  &__icon {
    position: absolute;
    top: -32px;
    left: 50%;
    width: 64px;
    height: 64px;
    transform: translateX(-50%);
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    height: $footer-height;

    @media (max-width: $size-small) {
      flex-direction: column;
    }
  }

  &__copy {
    text-align: left;
    flex-grow: 1;

    @media (max-width: $size-small) {
      text-align: center;
    }
  }

  p {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: -0.075px;
    text-transform: uppercase;
    margin: 0;
    text-align: left;

    @media (max-width: $size-small) {
      text-align: center;
    }
  }

  &__social {
    display: flex;
    gap: 8px;
    margin-top: 8px;

    @media (max-width: $size-small) {
      justify-content: center;
    }

    &Link {
      display: block;
    }
  }

  &__author {

    span {
      display: block;
      font-style: normal;
      font-weight: 500;
      font-size: 8px;
      line-height: 8px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      text-align: right;
      margin-bottom: 2px;

      @media (max-width: $size-small) {
        text-align: center;
      }
    }

    a {
      display: block;
    }
  }
}