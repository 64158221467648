

.Brands {
  padding: 128px 0 88px;
  background: $color-blue-dark;
  position: relative;
  overflow: hidden;

  @media (max-width: $size-medium) {
    padding: 80px 0;
  }

  @media (max-width: $size-small) {
    padding: 40px 0 0;
  }

  &__tree {
    margin-top: 64px;

    @media (max-width: $size-small) {
      margin-top: 40px;
    }
  }

  &__item {
    display: inline-block;
    width: auto;
    height: 64px;
    margin-bottom: 40px;

    @media (max-width: $size-medium) {
      margin-right: 40px;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }

    @media (max-width: 584px) {
      margin-bottom: 40px;
    }

    &--large {
      height: 80px;

      @media (max-width: $size-medium) {
        margin-right: 0;
      }
    }
  }

  &__cols {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 112px;
    margin-top: 24px;
    border-top: 1px solid $color-white-24;

    @media (max-width: $size-medium) {
      grid-template-columns: 1fr;
      width: 504px;
      margin: 64px auto 0;
      border-top: none;
      border-left: 1px solid $color-white-24;
      padding: 0 40px;
    }

    @media (max-width: 584px) {
      width: 290px;
      border-top: 1px solid $color-white-24;
      border-left: none;
      padding-top: 40px;
      margin-top: 0;
    }

    @media (max-width: 338px) {
      width: auto;
    }
  }

  &__col {
    padding-top: 56px;
    text-align: left;
    position: relative;

    @media (max-width: $size-medium) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-top: 0;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @media (max-width: 584px) {
      grid-template-columns: 1fr;
      margin-bottom: 0;
    }

    &:last-of-type:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: -56px;
      height: 100%;
      border-left: 1px solid $color-white-24;

      @media (max-width: $size-medium) {
        display: none;
      }
    }
  }
}