
.CrossDivider {

  &__badge {
    display: block;
    width: 180px;
    height: 180px;
    position: absolute;
    top: -90px;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: $size-small) {
      width: 80px;
      height: 80px;
      top: -40px;
    }
  }

  &__item {
    display: block;
    width: 32px;
    height: 32px;
    position: absolute;

    @media (max-width: $size-small) {
      width: 20px;
      height: 20px;
    }

    * {
      stroke: $color-blue;
    }

    &--filled * {
      stroke: none;
      fill: $color-green;
    }

    @for $i from 1 through 6 {
      &:nth-child(#{$i}) {
        animation: floatingCross ease-in-out 5s infinite;
        animation-delay: -600ms * $i;
        will-change: transform;
      }
    }

    &:nth-child(1) {
      top: 24px;
      left: 0;
    }

    &:nth-child(2) {
      top: 96px;
      left: 28%;

      @media (max-width: $size-small) {
        top: 56px;
      }
    }

    &:nth-child(3) {
      top: 96px;
      right: 28%;

      @media (max-width: $size-small) {
        top: 56px;
      }
    }

    &:nth-child(4) {
      top: 24px;
      right: 16%;

      @media (max-width: $size-small) {
        top: 16px;
      }
    }

    &:nth-child(5) {
      right: 0;
      top: 72px;

      @media (max-width: $size-small) {
        top: 40px;
      }
    }

    &:nth-child(6) {
      top: 148px;
      left: calc(50% - 16px);

      @media (max-width: $size-small) {
        top: 96px;
      }
    }
  }
}

@keyframes floatingCross {
  0% {
    transform: none;
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: none;
  }
}