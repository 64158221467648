
.Video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  animation: videoIn ease-in-out 200ms forwards;
  opacity: 0;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(11, 28, 99, 0.92);
    z-index: -1;
  }

  &__player {
    display: block;
    width: 100%;
    max-width: 1024px;
    height: auto;
    margin: auto;
    text-align: right;
    position: relative;

    & > div {
      width: 100%;
      aspect-ratio: 1024 / 527;

      @supports not (aspect-ratio: 1024 / 527) {
        height: 576px;

        @media (max-width: $size-medium) {
          height: 420px;
        }

        @media (max-width: $size-small) {
          height: 220px;
        }
      }
    }

    &Youtube {
      width: 100%;
      height: 100%;
      background: $color-blue-dark;
    }
  }

  &__button {
    position: absolute;
    top: -44px;
    right: -16px;

    @media (max-width: 1046px) {
      position: fixed;
      right: 0;
      top: 0;
    }
  }
}

@keyframes videoIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}