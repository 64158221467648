

.Story {
  scroll-snap-align: start;
  padding: 308px 0 128px;
  position: relative;

  @media (max-width: $size-medium) {
    padding: 260px 0 80px;
  }

  @media (max-width: $size-small) {
    padding: 164px 0 40px;
  }

  &__component {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;

    @media (max-width: $size-medium) {
      flex-direction: row-reverse;
      justify-content: center;
      margin-bottom: 40px;
    }

    @media (max-width: $size-small) {
      flex-direction: column-reverse;
      align-items: center;

      &:last-child {
        margin-bottom: 0;
      }
    }

    h3,
    p {
      text-align: left;

      @media (max-width: $size-small) {
        text-align: center;
      }
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.01em;
    }

    &List {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 92px;
      margin-top: 64px;

      @media (max-width: $size-medium) {
        grid-template-columns: 1fr;
      }
    }

    &:not(:last-child) > div:last-child {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  &__icon {
    display: block;
    width: 140px;
    margin-top: 40px;

    @media (max-width: $size-medium) {
      margin-top: 0;
      margin-right: 40px;
    }

    @media (max-width: $size-small) {
      width: 80px;
      height: 96px;
      margin-right: 0;
      margin-bottom: 24px;
    }
  }

  &__symbol {
    font-family: $font-primary;
    position: absolute;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    text-align: center;
    bottom: 50px;
    right: 12px;
    height: 113px;
    line-height: 113px;

    @media (max-width: $size-medium) {
      display: none;
    }
  }
}