
html {
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: $font-default;
  font-weight: 400;
  quotes: "“" "”";
  text-align: center;
  color: $color-white;
}

* {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: 'liga', 'kern';
}

h1, h2, h3, h4, h5, h6, button {
  font-family: $font-primary;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.075px;
  text-transform: uppercase;

  @media (max-width: $size-medium) {
    font-size: 32px;
    line-height: 38px;
  }

  @media (max-width: $size-small) {
    font-size: 24px;
    line-height: 32px;
  }
}

h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  letter-spacing: -0.075px;
  text-transform: uppercase;
  margin-bottom: 24px;

  @media (max-width: $size-medium) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: $size-small) {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
  }
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  color: $color-green;
  margin-bottom: 16px;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.075px;
  max-width: 594px;
  margin: 16px auto;

  @media (max-width: $size-small) {
    font-size: 14px;
    line-height: 22px;
    margin: 12px auto;
  }
}

a {
  text-decoration: none;
}