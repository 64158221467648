$gallery-height: 394px;
$gallery-height-small: 220px;

.Gallery {
  height: $gallery-height;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  padding: 24px 0;

  @media (max-width: $size-small) {
    height: $gallery-height-small;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__container {
    position: relative;
    width: 20000px;
    height: 100%;
    text-align: left;
    padding: 0 calc((100% - 1024px) / 2);
    display: flex;
    gap: 16px;

    @media (max-width: 1072px) {
      padding: 0 24px;
    }
  }

  &__content {
    display: flex;
    gap: 16px;
    height: 100%;
  }

  &__wrapper {
    position: relative;
  }

  &__item {
    gap: 16px;
    position: relative;
    height: 100%;

    img {
      display: block;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: box-shadow ease-in-out 200ms, transform ease-in-out 200ms;
      will-change: box-shadow, transform;
      cursor: pointer;

      &:hover {
        box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
        transform: scale3d(1.02, 1.02, 1);
      }
    }

    &--half {
      display: flex;
      flex-direction: column;
      height: calc(50% - 8px);

      @media (max-width: $size-small) {
        flex-direction: row;
        height: 100%;
      }

      img {
        height: 100%;
      }
    }
  }

  &__section {
    display: flex;
    height: 100%;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
  }

  &__button {
    position: absolute;
    top: ($gallery-height / 2) - 20px;
    z-index: 1;
    background: $color-white;
    padding: 0;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    box-shadow: 0 4px 16px 0 rgb(0 0 0 / 72%);
    transition: all ease-in-out 200ms;

    @media (max-width: $size-small) {
      top: ($gallery-height-small / 2) - 20px;
    }

    svg {
      width: 32px;
      height: 32px;

      * {
        fill: $color-blue-dark;
      }
    }

    &--left {
      left: 24px;
    }

    &--right {
      right: 24px;
    }

    &--hidden {
      opacity: 0;
      visibility: hidden;
    }

    &:hover,
    &:focus {
      box-shadow: 0 4px 16px 0 rgb(0 0 0 / 72%);
    }
  }

  &__arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 15vw;
    cursor: pointer;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: opacity ease-in-out 200ms;
      opacity: 0;
      visibility: hidden;
    }

    &:hover:after {
      opacity: 1;
      visibility: visible;
    }

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      z-index: 1;
      transition: opacity ease-in-out 200ms;
      opacity: 0;
      visibility: hidden;
    }

    &:hover svg {
      opacity: 1;
      visibility: visible;
    }

    &--left {
      left: 0;

      &:after {
        background: linear-gradient(90deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 100%);
      }

      svg {
        left: 16px;
      }
    }

    &--right {
      width: 50%;
      right: 0;

      &:after {
        background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.5) 100%);
        left: auto;
        width: 15vw;
      }

      svg {
        right: 16px;
      }
    }
  }

  &__preview {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    animation: previewIn ease-in-out 200ms forwards;
    opacity: 0;

    &Wrapper {
      display: block;
      position: absolute;
      top: 44px;
      left: 44px;
      right: 44px;
      bottom: 44px;
      text-align: right;

      @media (max-width: $size-medium) {
        left: 0;
        right: 0;
      }
    }

    &Overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(11, 28, 99, 0.92);
      z-index: -1;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &Button {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }

    &Focusable {
      outline: none;
    }
  }
}

@keyframes previewIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}