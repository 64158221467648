$navigation-height: 128px;
$navigation-height-collapsed: 96px;

.Navigation {
  display: block;
  height: $navigation-height;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: background-color ease-in-out 400ms, height ease-in-out 400ms, border-bottom-color ease-in-out 400ms;
  will-change: background-color, height, border-bottom-color;
  border-bottom: 1px solid transparent;

  @media (max-width: $size-medium) {
    position: absolute;
  }

  &--withBackground {
    background-color: transparentize($color-blue-dark, 0.02);
    border-color: $color-black-24;
    height: $navigation-height-collapsed;

    @media (max-width: $size-medium) {
      background-color: transparent;
      border-color: transparent;
      height: $navigation-height;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    @media (max-width: $size-medium) {
      justify-content: center;
    }
  }

  &__brand {
    display: block;
    width: 118px;
    height: 56px;
    text-indent: -9999px;

    svg {
      display: block;
    }
  }

  &__menu {
    display: flex;
    column-gap: 24px;
    margin: 0;
    padding: 0;
    list-style: none;

    @media (max-width: $size-medium) {
      display: none;
    }

    &Item {
      display: block;
      color: $color-white;
      font-family: $font-primary;
      text-transform: uppercase;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.075px;

      &--selected {
        color: $color-green;
      }

      &Link {
        display: block;
        color: inherit;
      }
    }
  }

  .Container {
    height: 100%;
  }
}